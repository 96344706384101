import React, { FC } from "react";
import Layout from "../components/layout/Layout";
import Breadcrumb from "../components/layout/Breadcrumb";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

const title = "Pagină în construcție";

const PoliticaCookies: FC = () => (
    <Layout>
        <Helmet title={title} />
        <Breadcrumb title={title} />

        <section className="page-area section-default section-padding-100">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <p>Pagină momentan în construcție...</p>

                        <Link to="/" title="Acasă">Acasă</Link>
                    </div>
                </div>
            </div>
        </section>
    </Layout >
);

export default PoliticaCookies;
