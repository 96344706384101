import React, { FC } from "react";
import Layout from "../components/layout/Layout";
import Breadcrumb from "../components/layout/Breadcrumb";
import Login from "./login";
import { Helmet } from "react-helmet";
import UnderConstruction from "./under-construction";

const title = "Profil";

const Profile: FC = () => {
    const name = "", email = "";

    return <UnderConstruction />;

    const isAuthenticated = false;

    if (!isAuthenticated) {
        return <Login />;
    }

    return (
        <Layout>
            <Helmet title={title} />
            <Breadcrumb title={title} />

            <section className="page-area section-default section-padding-100">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="card">
                                <div className="card-header">Profil</div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item"><strong>Nume:</strong> {name}</li>
                                    <li className="list-group-item"><strong>Email:</strong> {email}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Profile;
