import React, { FC, useState } from "react";
import { Link } from "gatsby";
import validate from "validate.js";
import Layout from "../components/layout/Layout";
import Breadcrumb from "../components/layout/Breadcrumb";
import InvalidFormInput from "../components/InvalidFormInput";
import Profile from "./profile";
import { Helmet } from "react-helmet";
import UnderConstruction from "./under-construction";

const title = "Autentificare";

type FormDataType = {
    email: string;
    password: string;
};

const Login: FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState<FormDataType>({
        email: "",
        password: "",
    });

    return <UnderConstruction />;

    const isAuthenticated = false;

    const handleSetState = (key: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setState(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleLogin = async () => {
        setIsLoading(true);

        // firebaseAuth.signInWithEmailAndPassword(state.email, state.password)
        //     .then(() => {
        //         navigate("/profile");
        //     })
        //     .catch(error => {
        //         dispatch(addToast("danger", `A apărut o eroare! ${error.message}`));
        //     })
        //     .finally(() => {
        //         setIsLoading(false);
        //     });
    };

    const formValidation = validate({ email: state.email, password: state.password }, {
        email: {
            presence: { allowEmpty: false },
            email: true,
        },
        password: {
            presence: { allowEmpty: false },
            length: { minimum: 6, maximum: 30 },
        },
    });

    if (isAuthenticated) {
        return <Profile />;
    }

    return (
        <Layout isLoading={isLoading}>
            <Helmet title={title} />
            <Breadcrumb title={title} />

            <section className="page-area section-default section-padding-100">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="form-area">
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="email" className="required">Email</label>
                                            <input type="email" name="email" id="email" placeholder="Email..." value={state.email} onChange={handleSetState("email")} className="form-control" required />
                                            <InvalidFormInput formValidation={formValidation} fieldName="email" fieldValue={state.email} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password" className="required">Parolă</label>
                                            <input type="password" name="password" id="password" placeholder="Parolă..." value={state.password} onChange={handleSetState("password")} className="form-control" required />
                                            <InvalidFormInput formValidation={formValidation} fieldName="password" fieldValue={state.password} />
                                        </div>
                                        <div className="col text-center mb-5">
                                            <button className="btn btn-primary" onClick={handleLogin} disabled={true || isLoading || formValidation != null}>Autentificare</button>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <p className="small text-center">Nu ai cont? <Link to="/register">Creează un cont nou</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Login;
